import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { Meta } from 'src/app/_models/benchmark.models';
import { MetaService } from 'src/app/_services/meta.service';

@Component({
  selector: 'app-tela-graficos-meta',
  templateUrl: './tela-graficos-meta.component.html',
  styleUrls: ['./tela-graficos-meta.component.scss']
})
export class TelaGraficosMetaComponent implements OnInit {
  atual = moment();
  date = new FormControl(moment());
  metas: Meta[]
  minDate: Date;
  maxDate: Date;

  constructor(
    private metaService: MetaService
  ) {
    this.minDate = new Date(2020, 0, 1);
    this.maxDate = new Date(moment().year(), moment().month(), moment().date());
  }

  data: moment.Moment;
  novaData: moment.Moment;
  ultimaDataSaldo: Date;

  ngOnInit() {
    this.metaService.getMetasGraficos().subscribe(metas => this.metas = metas);
  }

  chosenYearHandler(normalizedYear: Date, datepicker: MatDatepicker<moment.Moment>) {
    console.log("date.value", this.date.value)
    console.log("date.value.format", this.date.value.format("YYYY/MM/DD"))
    const ctrlValue = this.date.value ? moment(this.date.value) : moment();
    const selectedYear = moment(normalizedYear).year();

    ctrlValue.year(selectedYear).month(11).date(31);

    this.date.setValue(ctrlValue);
    this.novaData = ctrlValue;

    if (datepicker) {
      datepicker.close();
    }
  }

  chosenMonthHandler(
    normalizedMonth: Date,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.date.value ? moment(this.date.value) : moment();
    const monthAsMoment = moment(normalizedMonth);

    ctrlValue.month(monthAsMoment.month());
    this.date.setValue(ctrlValue);
    this.novaData = ctrlValue;


  }

  dateSelectedHandler(selectedDate: moment.Moment) {
    this.date.setValue(selectedDate);
    this.novaData = selectedDate;
  }

  filtro() {
    console.log("data selecionada", this.novaData);
    this.data = this.novaData;
  }
}
