<span *ngIf="!relatorioDinamico">
  <h1 class="title">Enquadramentos {{ complementoTitulo }}</h1>
  <form>
    <mat-form-field>
      <input matInput [matDatepicker]="dp" [max]="atual" placeholder="Mês/Ano" [formControl]="date" [min]="minDate" />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker" [min]="minDate" [max]="maxDate">
      </mat-datepicker>
    </mat-form-field>
    <label class="hidePrint" style="margin-left: 0.5em;">Zoom:</label>
    <mat-slider class="hidePrint" min="0.5" max="1" step="0.01" value="1" [formControl]="zoom" color="primary">
    </mat-slider>
    <div style="float: right;">
      <button class="hidePrint" (click)="print()" mat-flat-button color="primary" style="margin-right: 5px;">
        <i class="fas fa-print"></i>Imprimir
      </button>
      <button *ngIf="exibirSaldo" class="hidePrint" (click)="geracaoPDF()" mat-flat-button color="primary">
        <i class="fas fa-file-pdf"></i>Gerar PDF
      </button>
    </div>
  </form>
</span>
<div style="display: flex;font-weight: bold;">
  <span style="text-indent: 1em;">
    Resolução
  </span>
  <span style="flex: 1"></span>
  <span style="width: 105px;">
    % Carteira
  </span>
  |
  <span style="width: 155px;text-align: right;">
    Saldo do grupo
    &nbsp;
  </span>
  |
  <span style="width: 205px; margin-right: 1.75em;text-align: right;">
    Situação
  </span>
</div>
<br />

<mat-expansion-panel [expanded]="relatorioDinamico" *ngFor="let grupo of  (agrupamentos | sort: 'idEnquadramento')">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ grupo.nomeGrupo }}
    </mat-panel-title>
    <span style="flex: 1"></span>
    <span style="width: 100px;">
      {{grupo.percentualSaldo | percent: "1.4-6" }}
    </span>
    &nbsp;&nbsp;|&nbsp;&nbsp;
    <span style="width: 150px;text-align: right;">
      {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2" }}
      &nbsp;
    </span>|
    &nbsp;
    <span style=" font-weight: bold; width: 200px;text-align: right;" [class.red]="!grupo.enquadrado"
      [class.green]="grupo.enquadrado">{{ grupo.enquadrado ? "Enquadrado" : "Não enquadrado" }}
    </span>
  </mat-expansion-panel-header>
  <table [style.zoom]="zoom.value" mat-table [dataSource]="grupo.fundos">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

    <ng-container matColumnDef="cnpjFundo">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; ">Fundo</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Fundo:</span>
        {{retorno.nomeFundo}}
        <br />
        <strong>CNPJ: </strong>{{ retorno.cnpjFundo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef>Saldo</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Saldo:</span>
        {{ retorno.saldoFinal | currency: "BRL":"symbol":"1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; ">Cliente</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Cliente:</span>
        {{ retorno.nome }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldoCotas">
      <th mat-header-cell *matHeaderCellDef>Saldo Cotas</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Saldo Cotas:</span>
        {{ retorno.saldoCotas }}
      </td>
    </ng-container>

    <ng-container matColumnDef="participacaoPL">
      <th mat-header-cell *matHeaderCellDef>% PL</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">% PL:</span>
        {{ retorno.percentualParticipacao | percent: "1.4-6" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="patrimonioLiquido">
      <th mat-header-cell *matHeaderCellDef>P. Líq.</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">P. Líquido:</span>
        {{ retorno.plFundo | currency:'BRL'}}
      </td>
    </ng-container>



    <ng-container matColumnDef="participacaoCarteira">
      <th mat-header-cell *matHeaderCellDef>% Carteira</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">% PL:</span>
        {{ retorno.percentualCarteira | percent: "1.4-6" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="limites">
      <th mat-header-cell *matHeaderCellDef>Limites</th>
      <td mat-cell *matCellDef="let retorno">
        <span class="mobile-label">Limites:</span>
        <span *ngIf="retorno.limite">
          Lim. Resol.: {{ retorno.limite | percent: "1.2-4" }}
          <br />
        </span>
        <span>
          Lim. Politica: {{ (retorno.limitePolitica || retorno.limite) | percent: "1.2-4" }}
          <br />
        </span>

        <!-- <span>
            Lim. CNPJ: {{ 1 | percent: "1.2-4" }}
            <br />
          </span> -->

        <!-- 136 - Fundos Renda Fixa 100% TP/ETF - Art. 7º, I, "b" - alterado para 100% -->
        <!-- 156 e 176 tambem sao enquadramentos do mesmo tipo 136, e sao PRO-GESTAO -->
        <span>
          Lim. CNPJ: {{ retorno && (retorno.idEnquadramento == 136 || retorno.idEnquadramento == 156 || retorno.idEnquadramento == 176) ? (1 | percent: "1.2-4") : (0.2 | percent: "1.2-4") }}
          <br />
        </span>

        <span *ngIf="retorno.tipoEnquadramento === 'RPPS'">
          Lim. PL.: {{ retorno.limiteParticipacao | percent: "1.4-4" }}
        </span>
        <span *ngIf="retorno.tipoEnquadramento === 'FP'">
          Lim. Inf.: {{ retorno.limiteInferior | percent: "1.3-4" }}
          <br />
          Lim. Sup.: {{ retorno.limiteSuperior | percent: "1.3-4" }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="situacao">
      <th mat-header-cell *matHeaderCellDef style="width: 10%; text-align: right;">
        Situação
      </th>
      <td mat-cell *matCellDef="let retorno" style="width: 10%; text-align: right;">
        <span class="mobile-label">Situação:</span>
        <span [class.red]="retorno.situacaoEnquadramento !== 'Enquadrado'"
          [class.green]="retorno.situacaoEnquadramento === 'Enquadrado'">
          {{ retorno.situacaoEnquadramento }}
        </span>
      </td>
    </ng-container>
  </table>
  <span style="text-indent: 1em;" *ngIf="!relatorioDinamico">
    Saldo: {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2-6" }}
  </span>
</mat-expansion-panel>
<span style="display: block;" class="break-page"></span>

<br />
<h3>Por benchmark</h3>

<br />
<div style="display: flex;font-weight: bold;">
  <span style="text-indent: 1em;">
    Benchmark
  </span>
  <span style="flex: 1"></span>
  <span style="width: 105px;">
    % Carteira
  </span>
  |
  <span style="width: 155px; margin-right: 1.75em; text-align: right;">
    Saldo do grupo
  </span>
</div>
<br />
<mat-expansion-panel [expanded]="relatorioDinamico"
  *ngFor="let grupo of  (agrupamentoBenchmarks | sortReverse: 'saldoGrupo')">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ grupo.nomeGrupo }}
    </mat-panel-title>
    <span style="flex: 1"></span>
    <span style="width: 100px;">
      {{grupo.percentualSaldo | percent: "1.4-6" }}
    </span>
    &nbsp;
    |&nbsp;&nbsp;
    <span style="width: 150px;text-align: right;">
      {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2" }}
    </span>
  </mat-expansion-panel-header>
  <table [style.zoom]="zoom.value" mat-table [dataSource]="grupo.fundos">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayAdminBench"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayAdminBench"></tr>

    <ng-container matColumnDef="cnpjFundo">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; ">Fundo</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Fundo:</span>
        {{retorno.nomeFundo}}
        <br />
        <strong>CNPJ: </strong>{{ retorno.cnpjFundo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentualSaldo">
      <th mat-header-cell *matHeaderCellDef style="text-align: right; width: 15%;">% saldo</th>
      <td mat-cell *matCellDef="let retorno" style="text-align: right;">
        <span class="mobile-label">%saldo:</span>
        {{ retorno.percentualSaldo | percent: "1.4-6" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef style="text-align: right;width:20%">Saldo</th>
      <td mat-cell *matCellDef="let retorno" style="text-align: right;">
        <span class="mobile-label">Saldo:</span>
        {{ retorno.saldoFinal | currency: "BRL":"symbol":"1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; ">Cliente</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Cliente:</span>
        {{ retorno.nome }}
      </td>
    </ng-container>
  </table>
  <span style="text-indent: 1em;" *ngIf="!relatorioDinamico">
    Saldo: {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2" }}
  </span>
</mat-expansion-panel>

<br />
<span style="display: block;" class="break-page"></span>

<h3>Por administradores</h3>
<br />
<div style="display: flex;font-weight: bold;">
  <span style="text-indent: 1em;">
    Administrador
  </span>
  <span style="flex: 1"></span>
  <span style="width: 100px;">
    % Carteira
  </span>
  |
  <span style="width: 150px; margin-right: 1.75em;text-align: right; text-indent: .2em;">
    Saldo do grupo
  </span>
</div>
<br />
<mat-expansion-panel [expanded]="relatorioDinamico"
  *ngFor="let grupo of (agrupamentoAdmin | sortReverse: 'saldoGrupo')">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ grupo.nomeGrupo }}
    </mat-panel-title>
    <span style="flex: 1"></span>
    <span style="width: 105px;">
      {{grupo.percentualSaldo | percent: "1.4-6" }}
    </span>
    &nbsp;
    |
    <span style="width: 155px;text-align: right;">
      {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2" }}
    </span>
  </mat-expansion-panel-header>
  <table [style.zoom]="zoom.value" mat-table [dataSource]="grupo.fundos">
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayAdminBench"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayAdminBench"></tr>

    <ng-container matColumnDef="cnpjFundo">
      <th mat-header-cell *matHeaderCellDef>Fundo</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Fundo:</span>
        {{retorno.nomeFundo}}
        <br />
        <strong>CNPJ: </strong>{{ retorno.cnpjFundo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="percentualSaldo">
      <th mat-header-cell *matHeaderCellDef style="text-align: right; width: 15%;">% saldo</th>
      <td mat-cell *matCellDef="let retorno" style="text-align: right;">
        <span class="mobile-label">%saldo:</span>
        {{ retorno.percentualSaldo | percent: "1.4-6" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="saldo">
      <th mat-header-cell *matHeaderCellDef style="text-align: right; width: 15%;">Saldo</th>
      <td mat-cell *matCellDef="let retorno" style="text-align: right;">
        <span class="mobile-label">Saldo:</span>
        {{ retorno.saldoFinal | currency: "BRL":"symbol":"1.2" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cliente">
      <th mat-header-cell *matHeaderCellDef style="width: 25%; ">Cliente</th>
      <td mat-cell *matCellDef="let retorno" style="padding-right: 1em;">
        <span class="mobile-label">Cliente:</span>
        {{ retorno.nome }}
      </td>
    </ng-container>
  </table>
  <span style="text-indent: 1em;" *ngIf="!relatorioDinamico">
    Saldo: {{grupo.saldoGrupo | currency: "BRL":"symbol":"1.2-6" }}
  </span>
</mat-expansion-panel>

<table *ngIf="false" [style.zoom]="zoom.value" mat-table [dataSource]="enquadramentos">
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

  <ng-container matColumnDef="cnpjFundo">
    <th mat-header-cell *matHeaderCellDef>CNPJ Fundo</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label" style="width: 9%;">CNPJ Fundo:</span>
      {{ retorno.cnpjFundo }}
    </td>
  </ng-container>

  <ng-container matColumnDef="saldo">
    <th mat-header-cell *matHeaderCellDef>Saldo</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo:</span>
      {{ retorno.saldoFinal | currency: "BRL":"symbol":"1.2-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="tipoEnquadramento">
    <th mat-header-cell *matHeaderCellDef>Tipo enquadramento</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Tipo enquadramento:</span>
      ({{ retorno.tipoEnquadramento }}) - {{ retorno.legislacao }}
    </td>
  </ng-container>

  <ng-container matColumnDef="cliente">
    <th mat-header-cell *matHeaderCellDef>Cliente</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Cliente:</span>
      {{ retorno.nome }}
    </td>
  </ng-container>

  <ng-container matColumnDef="saldoCotas">
    <th mat-header-cell *matHeaderCellDef>Saldo Cotas</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Saldo Cotas:</span>
      {{ retorno.saldoCotas | currency: "BRL":"symbol":"1.2-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="participacaoPL">
    <th mat-header-cell *matHeaderCellDef>% PL</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">% PL:</span>
      {{ retorno.percentualParticipacao | percent: "1.4-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="participacaoCarteira">
    <th mat-header-cell *matHeaderCellDef>% Carteira</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">% PL:</span>
      {{ retorno.percentualCarteira | percent: "1.4-6" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="limites">
    <th mat-header-cell *matHeaderCellDef>Limites</th>
    <td mat-cell *matCellDef="let retorno">
      <span class="mobile-label">Limites:</span>
      <span *ngIf="retorno.limitePolitica">
        Lim. Politica: {{ retorno.limitePolitica | percent: "1.2-4" }}
        <br />
      </span>
      <span *ngIf="retorno.limite">
        Lim.: {{ retorno.limite | percent: "1.2-4" }}
        <br />
      </span>
      <span *ngIf="retorno.tipoEnquadramento === 'RPPS'">
        Lim. PL.: {{ retorno.limiteParticipacao | percent: "1.4-4" }}
      </span>
      <span *ngIf="retorno.tipoEnquadramento === 'FP'">
        Lim. Inf.: {{ retorno.limiteInferior | percent: "1.3-4" }}
        <br />
        Lim. Sup.: {{ retorno.limiteSuperior | percent: "1.3-4" }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="situacao">
    <th mat-header-cell *matHeaderCellDef style="width: 10%; text-align: right;">
      Situação
    </th>
    <td mat-cell *matCellDef="let retorno" style="width: 10%; text-align: right;">
      <span class="mobile-label">Situação:</span>
      <span [class.red]="retorno.situacaoEnquadramento !== 'Enquadrado'"
        [class.green]="retorno.situacaoEnquadramento === 'Enquadrado'">
        {{ retorno.situacaoEnquadramento }}
      </span>
    </td>
  </ng-container>
</table>
<h3 *ngIf="exibirSaldo && !relatorioDinamico">
  Saldo da carteira: {{ saldoTotalCliente | currency: "BRL":"symbol":"1.2-6" }}
</h3>
