import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { UIService } from "src/app/_helpers/ui.service";
import { CotaMeta, Meta } from "src/app/_models/benchmark.models";
import { Carteira } from "src/app/_models/carteira.model";
import { Cliente } from "src/app/_models/cliente.model";
import { SortReversePipe } from "src/app/_pipes/sortReverse.pipe";
import { AuthService } from "src/app/_services/auth.service";
import { CarteiraService } from "src/app/_services/carteira.service";
import { ClienteService } from "src/app/_services/cliente.service";
import { MetaService } from "src/app/_services/meta.service";
import { MY_FORMATS } from "../clientes/carteira/relatorio-retornos/relatorio-retornos.component";

const NUMBER_OF_COMPONENTS = 3;

@Component({
  selector: "app-lamina-resumo",
  templateUrl: "./lamina-resumo.component.html",
  styleUrls: ["./lamina-resumo.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LaminaResumoComponent implements OnInit {
  atual = moment();
  //date = new FormControl(moment().add(1, "year").startOf("year"));
  date = new FormControl(moment().year(2024));

  imageUrl = "assets/imagens/logo - Copia.png";

  idCliente: number;
  cliente: Cliente;
  bacalhau: boolean = false;

  data: moment.Moment;
  novaData: moment.Moment;

  selic: Meta = { idMeta: 13, nome: "SELIC" };
  ipca: Meta = { idMeta: 11, nome: "IPCA" };
  inpc: Meta = { idMeta: 12, nome: "INPC" };

  quotesMeta: CotaMeta[];
  metaMes: CotaMeta;

  investimentos: Carteira[] = [];

  ultimaDataSaldo: Date;

  minDate: Date;
  maxDate: Date;
  loading: boolean = false;

  loadingFundos: boolean = false;
  loadings: boolean[] = [];
  dateSelected: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private uiService: UIService,
    private authService: AuthService,
    private clienteService: ClienteService,
    private carteiraService: CarteiraService,
    private metaService: MetaService,
    private route: ActivatedRoute
  ) {
    this.data = moment().add(-1, "month").startOf("month");
  }

  ngOnInit() {
    this.minDate = new Date(2024, 0, 1, 0, 0, 0);
    this.maxDate = new Date(2024, 11, 31, 23, 59, 59);
    this.idCliente = this.route.snapshot.params["idCliente"];

    this.clienteService
      .buscarClientePorId(this.idCliente)
      .subscribe((cliente) => {
        this.cliente = cliente;

        this.metaService.getMeta(this.cliente.meta).subscribe((meta) => {
          this.quotesMeta = meta.quotes;

          if (Array.isArray(this.quotesMeta)) {
            let metas = this.quotesMeta.find((c) =>
              moment(new Date(c.data)).isSame(this.data, "month")
            );

            if (metas) {
              this.metaMes = metas;
            } else {
              this.metaMes = {
                data: this.data.toDate(),
                percentual12: 0,
                percentual18: 0,
                percentual24: 0,
                percentual3: 0,
                percentual6: 0,
                percentualAno: 0,
                percentualMes: 0,
              };
            }
          }
        });
      });

    this.chosenMonthHandler(this.data, null);

    this.authService.willPrint$.next(true);
  }

  filtro() {
    this.bacalhau = true;
    this.data = this.novaData;
    this.loadingFundos = true;
    this.dateSelected = true;
    this.loading = true;
    const ctrlValue = this.date.value;
    const dt = this.data.clone().endOf("month");

    const newImageDate = ctrlValue.isAfter(moment("2024-12-31")) ||
      (ctrlValue.year() === 2024 && ctrlValue.month() === 11);

    this.imageUrl = newImageDate ? "assets/imagens/logo-diblasi-selo25anos.png" : "assets/imagens/logo - Copia.png";

    this.cdr.detectChanges();

    this.carteiraService
      .getCarteiraCliente(this.idCliente, null, dt.toDate())
      .subscribe((carteira) => {
        this.investimentos = carteira;
        this.ultimaDataSaldo = new SortReversePipe().transform(
          carteira,
          "dataSaldo"
        )[0].dataSaldo;
      });
    // this.loading = true;
    if (Array.isArray(this.quotesMeta)) {
      let metas = this.quotesMeta.find((c) =>
        moment(new Date(c.data)).isSame(ctrlValue, "month")
      );
      // console.log("metas2");
      // console.log(metas);
      if (metas) {
        this.metaMes = metas;
      } else {
        this.metaMes = {
          data: ctrlValue.toDate(),
          percentual12: 0,
          percentual18: 0,
          percentual24: 0,
          percentual3: 0,
          percentual6: 0,
          percentualAno: 0,
          percentualMes: 0,
        };
      }
    }

    for (let i = 0; i < NUMBER_OF_COMPONENTS; i++) {
      this.loadings[i] = false;
    }
    this.loading = false;
  }

  setCnpjFundos(cnpjs) {
    this.investimentos = cnpjs;
  }

  get loaded() {
    return this.loadings.every((c) => true);
  }

  updateLoading(i: number, status: boolean) {
    console.log(`${i} new status: ${status ? "loaded" : "loading"}`);
    this.loadings[i] = status;
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    // this.bacalhau = false;
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue.startOf("month"));
  }

  chosenMonthHandler(
    normalizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    //teste
    // this.loadingFundos = true;
    // this.dateSelected = true;
    // for (let i = 0; i < NUMBER_OF_COMPONENTS; i++) {
    //   this.loadings[i] = false;
    // }
    // this.bacalhau = false;
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    // ctrlValue.year(normalizedMonth.year());
    this.date.setValue(ctrlValue.startOf("month"));
    this.novaData = ctrlValue.startOf("month");
    const dt = this.novaData.clone().endOf("month");
    this.carteiraService
      .getCarteiraCliente(this.idCliente, null, dt.toDate())
      .subscribe((carteira) => {
        this.investimentos = carteira;
        this.ultimaDataSaldo = new SortReversePipe().transform(
          carteira,
          "dataSaldo"
        )[0].dataSaldo;
      });
    // this.loading = true;
    if (Array.isArray(this.quotesMeta)) {
      let metas = this.quotesMeta.find((c) =>
        moment(new Date(c.data)).isSame(ctrlValue, "month")
      );
      // console.log("metas2");
      // console.log(metas);
      if (metas) {
        this.metaMes = metas;
      } else {
        this.metaMes = {
          data: ctrlValue.toDate(),
          percentual12: 0,
          percentual18: 0,
          percentual24: 0,
          percentual3: 0,
          percentual6: 0,
          percentualAno: 0,
          percentualMes: 0,
        };
      }
    }
    if (datepicker) {
      datepicker.close();
    }
    this.loadingFundos = false;
  }

  print() {
    window.print();
  }
}
